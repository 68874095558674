/* Maintain existing styles, add the faded effect when loading */
.SignUpPage_MainSection.loading-active {
  opacity: 0.8;
  pointer-events: none;
}

.loadingSpinnerOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #f34100;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
