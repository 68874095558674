li,
ul,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mn-sec-csc {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 60, 0.5);
  z-index: +3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mn-dv-csc {
  background-color: rgb(23, 23, 23);
  width: 40%;
  height: 90%;
  overflow: auto;
  z-index: +3;
  border-radius: 6px;
  border: 1px solid rgb(70, 70, 70);
}

.tp-br-csc {
  width: 100%;
  color: rgb(235, 235, 235);
  padding: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(70, 70, 70);
  position: sticky;
  top: 0;
  z-index: +2;
  background-color: rgb(27, 27, 27);
}
.tp-br-csc a {
  color: rgb(235, 235, 235);
}
.tp-br-csc span {
  font-size: 20px;
}

.tab-bar-sts {
  width: 100%;
  margin-top: 5px;
}
.tab-bar-sts ul {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 25px;
}
.tab-bar-sts ul li {
  list-style: none;
  border-bottom: 2px solid rgb(23, 23, 23);
  /* background-color: aqua; */
  padding: 0px 5px;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
}
.tab-bar-sts ul li a:hover,
.tab-bar-sts ul li a:focus {
  color: rgb(130, 130, 130);
  transition: 0.3s;
}

/* Tabs */
.tab-bar-sts ul {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 25px;
}

.tab-bar-sts ul li {
  list-style: none;
  border-bottom: 2px solid transparent; /* Default: no underline */
  cursor: pointer;
  font-size: 14px;
  color: rgb(200, 200, 200);
  transition: color 0.3s, border-bottom 0.3s; /* Smooth transitions */
}

.tab-bar-sts ul li:hover {
  color: rgb(255, 255, 255); /* Brighter on hover */
}

.tab-bar-sts ul li.active {
  color: rgb(255, 255, 255); /* Active tab brighter */
  font-weight: 500; /* Emphasize active tab */
}

.tab-content {
  /* background-color: red; */
  width: 100%;
  flex-grow: 1; /* Ensures it expands to fill remaining space */
  padding: 20px;
  overflow-y: auto; /* Handles overflow if content exceeds height */
  box-sizing: border-box; /* Includes padding in height calculations */
}

.tab-content h3 {
  color: rgb(200, 200, 200);
  font-size: 22px;
}
.tab-content ul li {
  color: rgb(220, 220, 220);
  font-size: 14px;
}
.tab-content ul {
  margin-top: 10px;
}
.tab-content ul li span {
  color: rgb(170, 170, 170);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.cmp-infr-dv {
  padding: 25px 0px;
}
.cmp-infr-dv i {
  color: rgb(220, 220, 220);
}
.cmp-infr-dv label {
  font-size: 14px;
}

.mn-dv {
  display: flex;
  gap: 15px;
  border-bottom: 1px solid rgb(50, 50, 50);
  padding: 25px 0px;
  padding-top: 0;
}
.cmp-infr-dv {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cmp-infr-dv div {
  width: 100%;
  /* background-color: red; */
}

/* Leads */

.txt-area-dv {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}
.txt-area-dv label {
  font-size: 14px;
}

.txt-area-dv textarea {
  font-size: 14px;
  background-color: rgb(27, 27, 27);
  border-radius: 5px;
  color: rgb(235, 235, 235);
  min-height: 50px;
  height: 80px;
  max-height: 200px;
}
