.mainSection_dash .mainDiv_dash .leads_Container_div {
  background-color: rgb(30, 30, 30);
  border: 1px solid rgb(70, 70, 70);
  width: 80%;
  height: calc(100vh - 200px);
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  min-height: 100px;
}

.mainSection_dash .mainDiv_dash .leads_Container_div .topbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  border-bottom: 1px solid rgb(70, 70, 70);
  padding-bottom: 10px;
}

.search-input {
  width: 55%;
  padding: 7px 15px 7px 40px !important; /* Space for icon */
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: rgb(40, 40, 40);
  color: rgb(200, 200, 200);
  border: 2px solid rgb(60, 60, 60);
  font-size: 14px;
}

.search-input::placeholder {
  color: rgb(150, 150, 150);
}

.search-input:focus {
  border: 2px solid rgb(100, 100, 100);
  position: relative;
}

.search-icon {
  position: absolute;
  left: 15px;
  font-size: 16px;
  color: #b0b0b0;
}

.mainDiv_dash .leads_Container_div .topbar .btnDivs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mainDiv_dash .leads_Container_div .topbar button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: rgb(30, 30, 30);
  outline: none;
  border: 1px solid rgb(70, 70, 70);
  color: rgb(200, 200, 200);
  border-radius: 5px;
}

.mainDiv_dash .leads_Container_div .topbar button:hover {
  background-color: rgb(70, 70, 70);
}

.filter_ic {
  border: 1px solid red;
  padding: 7px;
  border-radius: 4px;
  border: 1px solid rgb(70, 70, 70);
  background-color: rgb(30, 30, 30);
  cursor: pointer;
}

.filter_ic:hover {
  background-color: rgb(70, 70, 70);
}
.filter_ic i {
  color: rgb(200, 200, 200);
}

/* Leads Information Division */

.leads_infr_div {
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 45px);
}

.leads_infr_div table {
  width: 100%;
}

.leads_infr_div table tr:nth-child(1) {
  color: rgb(130, 130, 130);
  position: sticky;
  top: 0;
  background-color: rgb(30, 30, 30);
}
.leads_infr_div table tr:nth-child(1) th {
  font-size: 14px;
  font-weight: 500;
  border-top: none;
}
.leads_infr_div table tr {
  border-bottom: 1px solid rgb(70, 70, 70);
  cursor: pointer;
  color: rgb(230, 230, 230);
  background-color: rgb(30, 30, 30);
}
.leads_infr_div table tr:hover {
  background-color: rgb(55, 55, 55);
}
.leads_infr_div table tr td {
  padding: 10px 5px;
  font-size: 13px;
  border-right: 1px solid rgb(70, 70, 70);
}
.leads_infr_div table tr th {
  padding: 10px 5px;
  font-size: 13px;
  border-right: 1px solid rgb(70, 70, 70);
}
.leads_infr_div table tr th:nth-child(1) {
  border-left: 1px solid rgb(70, 70, 70);
}
.leads_infr_div table tr td:nth-child(1) {
  border-left: 1px solid rgb(70, 70, 70);
}

/* End of Leads Information Division */

/* Leads Create Division */
.leads_crt_div_hide {
  display: none;
}

.leads_crt_div_show {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(72, 74, 77, 0.8);
  z-index: +3;
}

.insertDiv {
  border: 1px solid red;
  min-width: 800px;
  width: 60%;
  max-width: 70%;
  min-height: 80%;
  max-height: 80%;
  margin: auto;
  background-color: #1e1f21;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 5px;
  overflow-y: auto;
}

.topbar_insrtLD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  padding: 10px 20px;
  border-bottom: 1px solid rgb(60, 60, 60);
  position: sticky;
  top: 0;
  background-color: #1e1f21;
}
.topbar_insrtLD h3 {
  color: rgb(200, 200, 200);
}

.topbar_insrtLD span {
  color: rgb(180, 180, 180);
  cursor: pointer;
  padding: 15px;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.topbar_insrtLD span:hover {
  background-color: #2a2b2d;
}

/* Leads Information Division */

.leads_infr_div_show {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(72, 74, 77, 0.2);
  z-index: +1;
}
.leads_infr_div_hide {
  display: none;
}
/* End of Leads Information Division */

.skelton-container {
  height: 15px !important;
  margin: 0 !important;
}

.skeleton-item {
  height: 20px !important;
}
.skeleton {
  margin: 0 !important;
}
