.funnel-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  background: repeating-linear-gradient(
    transparent,
    transparent 29px,
    rgba(100, 100, 100, 0.2) 30px
  );
  background-color: rgba(0, 0, 0, 0.3);
}
/* Wrapper for the funnel chart */

.funnel-chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 15px;
  padding: 20px;
  /* background-color: red; */
}

/* Individual funnel segments */
.funnel-segment {
  height: 50px;
  background-color: rebeccapurple;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.funnel-segment:hover {
  /* transform: scale(1.02); */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Funnel segment labels */
.funnel-label {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
