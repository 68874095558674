.mn-dv-tsk-assign {
  margin-top: 20px;
}

.mn-dv-tskan {
  /* background-color: red; */
  width: 30%;
  min-width: 500px;
  height: 80%;
  background-color: #1e1f21;
}

.topbar-tskasn {
  background-color: #2a2a2c;
}

.task-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}

.task-form label {
  font-weight: bold;
  color: #555;
}

.task-form textarea {
  height: 80px;
  resize: none;
}

.task-form button {
  width: 100%;
  padding: 6px 25px;
  font-size: 16px;
  color: white;
  background-color: #1e1f21;
  border: 1px solid rgb(70, 70, 70);
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.task-form input,
.task-form select {
  padding: 7px;
  font-size: 13px;
  color: #ddd;
  background-color: #1e1f21;
  border: 1px solid #333333;
  border-radius: 3px;
  outline: none;
  width: 100%;
}
.task-form button:hover {
  background-color: rgb(60, 60, 60);
}

.descriptionContainer {
  margin-top: 0;
}

.descriptionContainer label {
  color: rgb(180, 180, 180);
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.descriptionContainer textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 120px;
  background-color: #1e1f21;
  width: 100%;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgb(60, 60, 60);
  padding: 3px;
  font-size: 14px;
  color: rgb(220, 220, 220);
}

.descriptionContainer textarea:focus,
.descriptionContainer textarea:hover {
  background-color: rgb(40, 40, 40);
  border: 1px solid rgb(180, 180, 180);
}

.cstmSLCT input {
  font-size: 14px;
}
.cstmSLCT .custom-select {
  background-color: #1e1f21;
  /* background-color: red; */
  height: 35px;
  border-radius: 0;
}
.cstmSLCT input::placeholder {
  font-size: 14px;
}
.cstmSLCT .custom-select-options {
  font-size: 14px;
}
