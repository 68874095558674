.mainDiv_Navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 260px;
  height: 100vh;
  background-color: #2a2b2d;
  padding-bottom: 20px;
  border-right: 1px solid rgb(255, 255, 255, 0.1);
  overflow-y: auto;
  /* padding-top: 5px; */
}

.mainDiv_Navbar .dvd {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
}

.mainDiv_Navbar ul {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin: 0;
  padding: 10px;
}

.mainDiv_Navbar ul span {
  color: rgb(200, 200, 200);
  font-size: 14px;
  margin: 5px 0px;
  cursor: default;
}

.mainDiv_Navbar ul li {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  list-style: none;
}

.mainDiv_Navbar ul li a {
  text-decoration: none;
  font-size: 13px;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.mainDiv_Navbar ul li a i {
  margin-right: 5px;
  color: rgb(200, 200, 200);
}

.mainDiv_Navbar ul li a:hover,
.mainDiv_Navbar ul li a:focus {
  background-color: rgb(60, 60, 60);
}

.planDet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgb(200, 200, 200);
  font-size: 13px;
  padding: 15px;
  margin-top: auto;
  text-align: left;
  border: 1px solid rgb(70, 70, 70);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  cursor: default;
}
