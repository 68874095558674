h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}
.mainTaskSection {
  height: 100vh; /* Full viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  overflow-y: auto; /* Enables scrolling if content exceeds viewport height */
  color: white;
  padding-top: 50px; /* Space from top */
  background-color: rgb(26, 26, 26);
}

.mainTaskSection .topbar_main_tsk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid rgb(60, 60, 60);
  background-color: rgb(26, 26, 26);
}

.mainTaskSection .topbar_main_tsk h1 {
  font-size: 18px;
  font-weight: 400;
  color: #e1e1e1;
}
.mainTaskSection .topbar_main_tsk {
  display: flex;
  justify-content: space-between; /* Ensures content is spaced */
  align-items: center;
  position: sticky;
  top: 4px;
  z-index: +1;
}

.mainTaskSection .date_time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #e1e1e1;
}

.mainTaskSection .date_time i {
  margin: 0;
  padding: 0;
  margin-right: 8px;
  font-size: 16px;
  color: #a1a1a1;
}

.mainDivision_TaskPage {
  width: 100%;
  padding: 10px;
  padding-top: 0;
}

.topbar_second_tsk {
  border-bottom: 1px solid rgb(60, 60, 60);
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 1px solid red; */
  z-index: +1;
  position: sticky;
  top: 57px;
  background-color: rgb(26, 26, 26);
}

.topbar_second_tsk button {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid rgb(70, 70, 70);
  background-color: rgb(30, 30, 30);
  color: rgb(220, 220, 220);
  font-size: 13px;
  width: fit-content;
}
.topbar_second_tsk button:hover {
  background-color: rgb(50, 50, 50);
}

.topbar_second_tsk .search-input {
  width: 45%;
  padding: 7px 15px 7px 40px; /* Space for icon */
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: rgb(40, 40, 40);
  color: rgb(200, 200, 200);
  border: 1px solid rgb(60, 60, 60);
  font-size: 14px;
}

.topbar_second_tsk .search-input::placeholder {
  color: rgb(150, 150, 150);
}

.topbar_second_tsk .search-input:focus {
  border: 1px solid rgb(100, 100, 100);
}

.search-div-tsk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  /* border: 1px solid red; */
}
.search-div-tsk input {
  width: 100% !important;
}
.search-div-tsk i {
  position: relative;
  left: 30px;
  z-index: +1;
  color: rgb(150, 150, 150);
  font-size: 14px;
}

.main-tsk-div {
  width: 100%;
}

.main-tsk-div table {
  width: 100%;
}

.main-tsk-div table tr:nth-child(1) {
  /* background-color: rgb(40, 40, 40); */
  /* border-bottom: 1px solid rgb(60, 60, 60); */
  font-size: 13px;
  position: sticky;
  z-index: 0;
  top: 113px;
  background-color: rgb(35, 35, 35);
}

.main-tsk-div table th {
  padding: 6px;
  color: rgb(150, 150, 150);
  font-weight: 400;
}

.main-tsk-div table th:nth-child(1),
.main-tsk-div table th:nth-child(2),
.main-tsk-div table th:nth-child(3) {
  border-right: 1px solid rgb(50, 50, 50);
}

.main-tsk-div table td {
  padding: 9px 6px;
  font-weight: 400;
  border-bottom: 1px solid rgb(60, 60, 60);
}

.main-tsk-div table td:nth-child(1),
.main-tsk-div table td:nth-child(2),
.main-tsk-div table td:nth-child(3),
.main-tsk-div table td:nth-child(4),
.main-tsk-div table td:nth-child(5) {
  border-right: 1px solid rgb(50, 50, 50);
}

.main-tsk-div table tr:hover {
  background-color: rgb(35, 35, 35);
  cursor: pointer;
}
.main-tsk-div table tr td {
  font-size: 13px;
  gap: 10px;
}

.follow-up-task {
  background-color: rgb(35, 35, 35);
  color: rgb(200, 200, 200);
  padding: 5px 5px;
  font-size: 14px;
  border-radius: 5px;
}

.due-date-filter {
  background-color: rgb(35, 35, 35);
  color: rgb(200, 200, 200);
  padding: 5px 5px;
  font-size: 14px;
  border-radius: 5px;
}
