.tab-content-blg {
  padding: 0;
}

.tabs-container {
  display: flex;
  margin-top: 20px;
  /* border-bottom: 1px solid rgb(70, 70, 70); */
}

.tab {
  padding: 7px 15px;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  /* border-radius: 5px; */
  border: 1px solid transparent;
}

.tab.active {
  background-color: rgb(50, 50, 50);
  color: white;
}
.tab:hover {
  border: 1px solid rgb(50, 50, 50);
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table tr:hover {
  background-color: rgb(40, 40, 40);
  cursor: pointer;
}

th,
td {
  padding: 12px;
  text-align: left;
  border: 1px solid rgb(50, 50, 50);
}

th {
  background-color: transparent;
}

.due-date-filter {
  padding: 8px;
  width: 150px;
}

.topbar-btns {
  /* background-color: red; */
  border-bottom: 1px solid rgb(70, 70, 70);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs-container {
  width: fit-content;
}

.topbar-btns button {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid rgb(70, 70, 70);
  background-color: rgb(30, 30, 30);
  color: rgb(220, 220, 220);
  font-size: 13px;
  width: fit-content;
}
.topbar-btns button:hover {
  background-color: rgb(50, 50, 50);
}
