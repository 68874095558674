.mn-sec-glbl {
  display: flex;
  width: 50%;
  height: 80%;
  justify-content: center;
  align-items: center;
  background-color: rgb(35, 35, 35);
  overflow: auto;
}
.mn-sec-glbl p {
  font-size: 14px;
  color: rgb(200, 200, 200);
}
.global-search {
  color: white;
  width: 100%;
  height: 100%;
}

.search-bar {
  /* margin-bottom: 20px; */
  position: sticky;
  top: 0;
  background-color: rgb(35, 35, 35);
  padding: 5px 10px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  padding: 7px 15px 7px 15px; /* Space for icon */
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgb(50, 50, 50);
  color: rgb(200, 200, 200);
  border: 2px solid rgb(60, 60, 60);
  font-size: 13px;
}
.search-bar input:focus {
  border: 2px solid rgb(150, 150, 150);
}

.search-bar input::placeholder {
  color: rgb(180, 180, 180);
}

.search-results {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.search-results h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 400;
  color: rgb(200, 200, 200);
  /* background-color: red; */
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

table th {
  border: 1px solid rgb(60, 60, 60);
  padding: 8px;
  font-size: 13px;
  font-weight: 400;
  color: rgb(150, 150, 150);
}

table td {
  border: 1px solid rgb(60, 60, 60);
  padding: 8px;
  font-size: 13px;
  font-weight: 400;
}
