.custom-select-container {
  width: 100%;
  position: relative;
  /* margin: 20px; */
  border: 1px solid transparent;
  border-radius: 3px;
}
.custom-select-container:hover,
.custom-select-container:active,
.custom-select-container:focus {
  border: 1px solid rgb(70, 70, 70);
}

.custom-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(30, 30, 30);
  color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(70, 70, 70);
}

.custom-select-header {
  flex-grow: 1;
  font-size: 13px;
}

.custom-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ccc;
}

.custom-select-arrow.open {
  border-top: none;
  border-bottom: 5px solid #ccc;
}

.custom-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgb(35, 35, 35);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  height: fit-content;
  z-index: 10;
}

.custom-select-search {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  background-color: rgb(35, 35, 35);
  border: 1px solid rgb(70, 70, 70);
  border-radius: 5px;
  color: #ccc;
}

.custom-select-options {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgb(70, 70, 70);
}

.custom-select-option {
  padding: 8px;
  background-color: rgb(35, 35, 35);
  border-bottom: 1px solid rgb(50, 50, 50);
  color: #ccc;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: rgb(40, 40, 40);
}

.no-results {
  color: #ccc;
  padding: 8px;
}
