.SignUpPage_MainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(245, 245, 245);
}

.signUpForm {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 95%;
  background-color: transparent;
  border-radius: 25px;
}

.leftDiv_imgDiv_SignUpSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 25px;
}

.leftDiv_imgDiv_SignUpSection img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  opacity: 0.7;
}

.rightDiv_formDiv_SignUpSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: fit-content;
  padding: 50px 0px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(137, 137, 137, 0.1);
}

.icon_Div_Logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
}

.icon_Div_Logo img {
  width: 120px;
  margin-bottom: 25px;
}

.rightDiv_formDiv_SignUpSection h1 {
  font-size: 20px;
  color: rgb(50, 50, 50);
  margin: 0;
  font-weight: 500;
}

.rightDiv_formDiv_SignUpSection p {
  color: red;
  font-size: 13px;
  font-weight: 300;
  width: 100%;
}
.rightDiv_formDiv_SignUpSection .paraG {
  color: rgb(30, 30, 30);
  font-size: 13px;
  font-weight: 300;
}

.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
}

.formDiv .inputFieldDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.formDiv .inputFieldDiv label {
  width: 100%;
  color: rgb(30, 30, 30);
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
}

.formDiv .inputFieldDiv input {
  margin-top: 2px;
  width: 100%;
  outline: none;
  border: 1px solid rgba(200, 200, 200, 1);
  border-radius: 5px;
  color: rgb(50, 50, 50);
  font-size: 13px;
  padding: 7px 5px;
  transition: 0.3s;
}

.formDiv .inputFieldDiv input:focus {
  border: 1px solid rgb(243, 65, 0, 0.6);
}

.SignUpPage_MainSection .buttonDiv_form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 25px;
}

.SignUpPage_MainSection .buttonDiv_form a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonDiv_form a button {
  width: 100%;
  font-size: 15px;
  padding: 6px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f34100;
  color: white;
}

.newToPlatForm_span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-top: 15px;
  color: rgb(30, 30, 30);
  gap: 5px;
  font-weight: 300;
}

.termsCondition {
  font-size: 12px;
  margin-top: 10px;
  color: rgb(100, 100, 100);
}

.success {
  color: green;
}
