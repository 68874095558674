.mn-sc-unr {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(60, 60, 60, 0.5);
  z-index: +5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mn-dv-unr {
  height: 80%;
  background-color: rgb(33, 33, 33);
  border-radius: 5px;
  border: 1px solid rgba(70, 70, 70);
  width: 35%;
  min-width: 550px;
  overflow: auto;
}

.topbar-unr {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(70, 70, 70);
  position: sticky;
  top: 0;
  background-color: #1e1f21;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-unr i {
  color: rgb(220, 220, 220);
}

.topbar-unr h3 {
  color: rgb(220, 220, 220);
  font-weight: 400;
  font-size: 24px;
}

.users-unr {
  padding: 15px;
  padding-top: 0;
  border-bottom: 1px solid rgb(60, 60, 60);
}

.users-unr td {
  color: rgb(220, 220, 220);
}
.users-unr tr:hover {
  background-color: rgba(50, 50, 50);
}

.users-unr h3 {
  color: rgb(220, 220, 220);
  font-weight: 400;
  font-size: 24px;
  margin: 10px 0px;
}

.add-user-unr {
  padding: 15px;
}
.add-user-unr h3 {
  color: rgb(220, 220, 220);
  font-weight: 400;
  font-size: 24px;
  margin: 5px 0px;
}

.form-dv-unr i {
  color: rgb(220, 220, 220);
}

.form-dv-unr {
  padding: 0px 10px;
}
.form-dv-unr select {
  background-color: #1e1f21;
}
