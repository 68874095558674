/* Root wrapper for the chart */
.chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 35px;
  border-radius: 5px;
  background: repeating-linear-gradient(
    transparent,
    transparent 29px,
    rgba(100, 100, 100, 0.2) 30px
  );
  background-color: rgba(0, 0, 0, 0.3);
}

/* Main container for the chart */
.chart-container {
  display: flex;
  width: 100%;
  /* max-width: 800px; */
  /* background-color: blue; */
}

/* Y-Axis for values */
.y-axis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 10px;
  height: 300px;
}

.y-axis-value {
  font-size: 12px;
  text-align: right;
  color: rgb(230, 230, 230);
}

/* Wrapper for the bar chart and X-axis */
.bar-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* background-color: red; */
  /* padding: 0px 10px; */
}

/* Bar chart container */
.bar-chart {
  display: flex;
  /* align-items: flex-end; */
  /* background-color: red; */
  justify-content: space-between;
  gap: 10px;
  height: 300px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  /* padding: 10px 0; */
  padding: 5px 25px;
  padding-top: 0;
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.8); */
  overflow-x: auto;
}

/* Individual bar container */
.bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* background-color: red; */
}

/* Bars */
.bar {
  width: 60px;
  max-width: 150px;
  border-radius: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  min-height: 5px;
}

.bar:hover {
  transform: scale(1.02);
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
}

/* Labels for each bar */
.bar-label {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: rgb(230, 230, 230);
}

/* X-Axis labels */
.x-axis {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: red; */
  padding: 0px 25px;
  padding-top: 10px;
}

.x-axis-label {
  font-size: 12px;
  text-align: center;
  width: fit-content;
  word-wrap: break-word;
  color: rgb(230, 230, 230);
}
