.success {
  display: block;
  color: #00c220;
  font-size: 14px;
}

.success i {
  color: #00c220 !important;
}

.error {
  display: block;
  color: red;
  font-size: 14px;
}
.error i {
  color: red !important;
}
.hidden {
  display: none;
}
