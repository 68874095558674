.pie-chart-container {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 10px;
  border-radius: 5px;
  background: repeating-linear-gradient(
    transparent,
    transparent 29px,
    rgba(100, 100, 100, 0.2) 30px
  );
  background-color: rgba(0, 0, 0, 0.3);
  align-items: flex-start;
}

svg {
  width: 300px;
  /* background-color: red; */
  height: 300px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-top: 20px; */
  height: 100%;
  /* background-color: red; */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.legend-item span {
  /* color: red; */
  color: rgb(220, 220, 220);
  font-size: 13px;
  font-weight: 400;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

