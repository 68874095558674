.mainSection_dash {
  background-image: linear-gradient(
    to top,
    #141414,
    #171717,
    #1a1a1a,
    #1c1c1c,
    #1f1f1f,
    #232325,
    #27282b,
    #2a2d32,
    #2c363d,
    #2c3f46,
    #2d494d,
    #305350
  );
  height: 100vh; /* Full viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  color: white;
  overflow-y: auto; /* Enables scrolling if content exceeds viewport height */
  padding: 10px;
  padding-top: 60px; /* Space from top */
}
.mainSection_dash .mainDiv_dash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.mainSection_dash h3 {
  width: 100%;
  position: sticky;
  font-size: 22px;
  font-weight: 400;
}

.mainDiv_dash span {
  font-size: 15px;
  color: rgb(230, 230, 230);
}
.mainDiv_dash h2 {
  color: rgb(230, 230, 230);

  font-size: 30px;
}

.mainSection_dash span {
  font-weight: 500;
  font-size: 14px;
}

.mainSection_dash h2 {
  font-weight: 400;
}

.todaysDueTasks_Div,
.leadsByStatus,
.dealsByStage,
.leadsBySource,
.leadsByGeography {
  background-color: #2a2b2d;
  border: 1px solid rgb(70, 70, 70);
  width: 80%;
  margin-top: 20px;
  /* padding: 10px; */
  border-radius: 8px;
  min-height: 100px; /* Adjust the minimum height of these sections */
}

.todaysDueTasks_Div,
.leadsByStatus,
.dealsByStage,
.leadsBySource,
.leadsByGeography {
  height: fit-content; /* Example height for other sections */
}

/* Todays Due Tasks Styling */

.todaysDueTasks_Div {
  height: 400px;
  overflow: auto;
  background-color: rgb(30, 30, 30);
  /* padding: 15px; */
}
.todaysDueTasks_Header {
  position: sticky;
  top: 0;
  background-color: #2a2b2d;
  background-color: rgb(30, 30, 30);
  border-bottom: 1px solid rgb(70, 70, 70);
  z-index: +2;
  padding: 10px;
  /* background-color: rebeccapurple; */
}

.todaysDueTasks_Div h4 {
  font-size: 20px;
  font-weight: 400;
  color: rgb(245, 245, 245);
}

.todaysDueTasks_Div .dvd {
  min-height: 0px;
  background-color: rgb(70, 70, 70);
  /* margin-top: 10px; */
  
}

.todaysDueTasks_Div table {
  width: 100%;
  border-collapse: collapse;
}

.todaysDueTasks_Div table tr:nth-child(1) {
  position: sticky;
  top: 48px;
  background-color: rgb(30, 30, 30);
}
.todaysDueTasks_Div table tr {
  cursor: pointer;
  color: rgb(230, 230, 230);
}
.todaysDueTasks_Div table tr th {
  border-top: none;
  border-bottom: 1px solid rgb(70, 70, 70);
}

.todaysDueTasks_Div table tr:hover {
  background-color: rgb(55, 55, 55);
}
.todaysDueTasks_Div table tr td {
  padding: 7px 5px;
  font-size: 12.5px;
}
.todaysDueTasks_Div table tr td:nth-child(3),
.todaysDueTasks_Div table tr td:nth-child(4),
.todaysDueTasks_Div table tr td:nth-child(5),
.todaysDueTasks_Div table tr td:nth-child(6) {
  border-left: 1px solid rgb(70, 70, 70);
}

.todaysDueTasks_Div table tr td:nth-child(1) {
  text-align: center;
}
/* End of Todays Due Tasks Styling */

.taskView_div_show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(72, 74, 77, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: +3;
}
.taskView_div_hide {
  display: none;
}

.todaysDueTasks_Header button {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid rgb(70, 70, 70);
  background-color: rgb(40, 40, 40);
  color: rgb(220, 220, 220);
  font-size: 14px;
}
.todaysDueTasks_Header button:hover {
  background-color: rgb(50, 50, 50);
}

table {
  border: none;
}
