.mn-sec-tsk-nts {
  padding: 15px;
  border-radius: 8px;
  margin: 25px;
  margin-top: 0;
  border: 1px solid rgb(70, 70, 70);
}

.mn-dv-tsk-nts {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between chat messages */
}

.chat-message {
  /* border-radius: 10px; */
  font-size: 14px;
}

.user-note {
  width: 100%;
  border-bottom: 1px solid rgb(50, 50, 50);
  /* background-color: red; */
  padding: 5px;
}

.note-header {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 5px;
  width: 100%;
}

.note-header .user-name {
  font-weight: 500;
}

.note-content {
  line-height: 1; /* Adjust line spacing for readability */
  white-space: pre-wrap; /* Preserve formatting and wrap lines */
  word-wrap: break-word; /* Break words if they're too long */
  overflow-wrap: break-word; /* Ensure proper wrapping in modern browsers */
  color: rgb(230, 230, 230); /* Optional: Match the text color to the theme */
}
/* Wrapper for the profile photo and name */
.profile-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Profile photo styling */
.profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image fits within the circle */
}

/* Note header styles */
.note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
}

.note-header .user-name {
  font-weight: 500;
}

.note-header .note-time {
  font-size: 13px;
  font-weight: 400;
  color: rgb(150, 150, 150);
}
.note-content input {
  width: 100%;
  background-color: #1e1f21;
  border: 1px solid #1e1f21;
  outline: none;
  padding: 10px;
  padding-left: 5;
  color: rgb(200, 200, 200);
  border-radius: 3px;
}
.note-content input:focus,
.note-content input:hover {
  border: 1px solid #292a2d;
}
/* Wrapper for the input and update button */
.input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
  position: relative;
}

/* Input styling */
.note-input {
  flex: 1; /* Ensures input takes available space */
  padding: 8px;
  font-size: 14px;
  border: 1px solid rgb(70, 70, 70);
  border-radius: 4px;
  background-color: rgb(30, 30, 30);
  color: rgb(230, 230, 230);
  outline: none;
}

/* Button styling */
.update-btn {
  padding: 5px 10px;
  font-size: 12px;
  background-color: #1e1f21;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 5px; /* Position it to the end of the input */
  border: 1px solid rgb(70, 70, 70);
}

.update-btn:hover {
  background-color: rgb(50, 50, 50); /* Slightly darker green on hover */
  border: 1px solid rgb(70, 70, 70);
}
