.skelton-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.skeleton {
  border-radius: 4px;
  margin: 22px 0;
  width: 100%;
  height: 10px;
}

.skeleton-item {
  height: 30px;
  width: 100%;
}

@keyframes loading {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.skeleton {
  background: linear-gradient(to right, #313131 10%, #454545 15%, #313131 50%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
}

.skeleton-item-btn {
  height: 60px;
}

.skeleton-profile {
  height: 40px; /* Set the height */
  width: 40px; /* Make width equal to height */
  border-radius: 50%; /* Create the circular shape */
  background-color: #ccc; /* Optional: Skeleton background color */
}
