/* Main section styling */
.createLead_Section {
  padding: 25px;
  color: white;
  max-height: 100vh;
  border-radius: 8px;
}

/* Container styling */
.leadContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Left and Right section */
.leadLeft,
.leadRight {
  width: 48%;
}

/* Input fields styling */
.inputField {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #ddd;
}

/* Wrapper for icon and input */
.inputWrapper {
  display: flex;
  align-items: center;
}

/* Icon styling */
.inputWrapper i {
  color: #ddd;
  margin-right: 10px;
}

/* Input fields, select fields, and textareas */
.inputField input,
.inputField select,
.inputField textarea {
  padding: 7px;
  font-size: 13px;
  color: #ddd;
  background-color: #1e1f21;
  border: 1px solid #333333;
  border-radius: 3px;
  outline: none;
  width: 100%;
}

/* Placeholder color */
.inputField input::placeholder,
.inputField textarea::placeholder {
  color: rgb(120, 120, 120);
}

/* Focus effect (simplified, no border) */
.inputField input:focus,
.inputField select:focus,
.inputField textarea:focus,
.inputField input:hover,
.inputField select:hover,
.inputField textarea:hover {
  background-color: #27282b;
}

/* Textarea resizing */
.inputField textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 120px;
}

/* Save button styling */
.saveButton {
  width: 100%;
  padding: 6px 25px;
  font-size: 16px;
  color: white;
  background-color: rgb(30, 30, 30);
  border: 1px solid rgb(70, 70, 70);
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
}

/* Hover effect for Save button */
.saveButton:hover {
  background-color: rgb(60, 60, 60);
}

/* Change the calendar icon color to white (for webkit browsers like Chrome and Safari) */
.inputField input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Progression Tracker */
.progressionTracker {
  margin-top: 20px;
}

.progressionTracker h3 {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

/* Status container with shadow effect */
.statusContainer {
  display: flex;
  justify-content: space-between;
}

.statusItem {
  padding: 10px 15px;
  font-size: 13px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(145deg, #333, #444);
  transition: all 0.3s ease;
  width: 25%;
}
.statusItem:first-child {
  clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0% 50%, 0% 0%);
}

.statusItem:last-child {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 4% 50%, 0% 0%);
}

.statusItem:not(:first-child):not(:last-child) {
  clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 4% 50%, 0% 0%);
}
.statusItem.activeStatus {
  background: linear-gradient(145deg, #cf3700, #ce5200);
  color: #ffffff;
}

/* When the status is Closed - Converted, all previous stages will turn green */
.statusItem.convertedStatus {
  background: linear-gradient(145deg, #28a745, #218838);
  color: #ffffff;
}

/* If the lead is converted, all statuses before it should also turn green */
.statusItem.convertedStatus,
.statusItem.convertedStatus + .statusItem,
.statusItem.convertedStatus + .statusItem + .statusItem {
  background: linear-gradient(
    145deg,
    #28a745,
    #218838
  ); /* Green background for converted */
  color: white;
}

.button-container {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.button-container button {
  width: fit-content !important;
  font-size: 14px;
  padding: 7px 8px;
  flex: 3;
}
.button-container .whatsappButton {
  background-color: #28a745;
}
.button-container .whatsappButton:hover {
  background-color: #218838; /* Slightly darker green for hover effect */
}

.cnvrtBTN:hover {
  background-color: #a54200 !important;
  
}
