.mainSectionTopNav {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 260px);
  background-color: #2a2b2d;
  position: absolute;
  left: 260px;
  top: 0;
  padding: 8px 25px;
  z-index: +2;
}

.mainSectionTopNav .createBTN {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(80, 80, 80);
  padding: 5px 10px;
  gap: 6px;
  border-radius: 6px;
  background-color: rgb(55, 55, 55);
  cursor: pointer;
}
.mainSectionTopNav .createBTN:hover {
  background-color: rgb(45, 45, 45);
}

.mainSectionTopNav .createBTN .plusICON {
  color: #f34100;
  margin-right: 5px;
}

.mainSectionTopNav .createBTN span {
  margin: 0;
  padding: 0;
  font-size: 14px;
  z-index: +2;
  user-select: none;
}

.accountDet_topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.accountDet_topbar i:nth-child(1) {
  color: rgb(180, 180, 180);
  background-color: rgb(55, 55, 55);
  padding: 7px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid rgb(70, 70, 70);
  cursor: pointer;
  margin: 0px 5px;
}
.accountDet_topbar i:hover {
  background-color: rgb(80, 80, 80);
}

.accountDet_topbar img {
  border-radius: 50%;
}
.accountDet_topbar a:hover img {
  filter: brightness(0.8); /* Darken the image on hover */
}

.accountDet_topbar a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: rgb(180, 180, 180);
}

.searchBar_topbar {
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: rgba(60, 60, 60, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: +5;
}

.crt-dv-glbl {
  width: 160px;
  height: fit-content;
  background-color: rgb(35, 35, 35);
  border: 1px solid rgb(80, 80, 80);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.crt-dv-glbl li {
  background-color: transparent;
  padding: 10px 15px;
  list-style: none;
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgb(80, 80, 80);
  font-size: 14px;
}

.crt-dv-glbl li i {
  color: rgb(220, 220, 220);
}
.crt-dv-glbl li:hover {
  background-color: rgb(45, 45, 45);
}

.searchDiv {
  width: 35%;
}

.searchDiv input {
  width: 100%;
  padding: 10px;
  padding: 7px 15px 7px 15px; /* Space for icon */
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgb(50, 50, 50);
  color: rgb(200, 200, 200);
  border: 2px solid rgb(60, 60, 60);
  font-size: 13px;
}

.searchDiv input:focus {
  border: 2px solid rgb(150, 150, 150);
}

.searchDiv input::placeholder {
  color: rgb(180, 180, 180);
}

/* Logout Section */

.user-profiles-div-topbar {
  position: absolute;
  top: 50px;
  right: 25px;
  background-color: rgb(45, 45, 45);
  border-radius: 5px;
  border: 1px solid rgb(70, 70, 70);
  z-index: +5;
}
.user-profiles-div-topbar ul li {
  padding: 7px 25px;
  padding-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.user-profiles-div-topbar ul li:hover {
  background-color: rgb(55, 55, 55);
}

.spinnerDiv {
  position: fixed;
  top: 0;
  left: 0;
  z-index: +5;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 60, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.spinnerDiv p {
  color: white;
  font-size: 14px;
}
