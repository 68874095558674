h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}

.mnsec-cntcs-pg {
  height: 100vh; /* Full viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  overflow-y: auto; /* Enables scrolling if content exceeds viewport height */
  color: white;
  padding-top: 50px; /* Space from top */
  background-color: rgb(26, 26, 26);
}

