.SignInPage_MainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* background-color: rgb(2, 0, 31); */
  background-color: rgb(245, 245, 245);
}

.loginForm {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 98%;
  height: 95%;
  /* border: 1px solid red; */
  background-color: transparent;
  border-radius: 25px;
}

.leftDiv_imgDiv_SignInSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 25px;
  /* border: 1px solid red; */
}

.leftDiv_imgDiv_SignInSection img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  opacity: 1;
}

.rightDiv_formDiv_SignInSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: fit-content;
  padding: 50px 0px;
  margin-top: auto;
  margin-bottom: auto;
  /* border: 1px solid red; */
  background-color: rgb(2, 0, 31);
  background-color: rgb(250, 250, 250);
  box-shadow: 0px 0px 25px rgba(137, 137, 137, 0.1);
  background-color: white;
}

.icon_Div_Logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid red; */
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
  /* background-color: rebeccapurple; */
}

.icon_Div_Logo img {
  height: fit-content;
  width: fit-content;
  margin-bottom: 25px;
  /* background-color: red; */
}

.rightDiv_formDiv_SignInSection h1 {
  font-size: 20px;
  /* width: 100%; */
  /* border: 1px solid red; */
  padding: 10px;
  color: rgba(245, 245, 245, 0.89);
  color: rgb(50, 50, 50);
  margin: 0;
  padding: 0;
  font-weight: 500;
}
.rightDiv_formDiv_SignInSection p {
  color: rgba(255, 255, 255, 0.808);
  color: black;
  color: rgb(30, 30, 30);
  font-size: 13px;
  font-weight: 300;
}

/* Form Division */

.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
}

.formDiv .inputFieldDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.formDiv .inputFieldDiv label {
  width: 100%;
  color: rgba(255, 255, 255, 0.815);
  color: black;
  color: rgb(30, 30, 30);
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
}
.formDiv .inputFieldDiv input {
  margin-top: 2px;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  border: 1px solid rgba(200, 200, 200, 1);

  border-radius: 5px;
  color: rgba(255, 255, 255, 0.705);
  color: rgb(50, 50, 50);
  font-size: 13px;
  padding: 7px 5px;
  transition: 0.3s;
}

.formDiv .inputFieldDiv input:focus {
  border: 1px solid rgb(243, 65, 0, 0.6);
}

.rememberMe_Div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
}
.rememberMe_Div span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
/* .rememberMe_Div span input {
} */
.rememberMe_Div span label {
  color: rgba(255, 255, 255, 0.815);
  color: black;
  font-size: 13px;
  font-weight: 300;
}
.rememberMe_Div a {
  font-size: 13px;
  font-weight: 400;
  color: royalblue;
}

.buttonDiv_form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonDiv_form button {
  width: 100%;
  font-size: 15px;
  padding: 6px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
  background-color: #f34100;
  color: white;
}

.newToPlatForm_span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.815);
  color: rgb(30, 30, 30);
  gap: 5px;
  font-weight: 300;
}

/* End of Form Division */

.loadingSpinnerOverlay_signIn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  z-index: 9999;
}

.spinner_signIn {
  width: 80px;
  height: 80px;
  border: 2px solid #f3f3f3;
  border-top: 15px solid #f34100;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
