.mainSection_tv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.mainDiv_tv {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 50%;
  height: 80%;
  background-color: #1e1f21;
  border: 1px solid #464646;
  border-radius: 8px;
  overflow: hidden;
  color: #bfbfbf;
  overflow-y: auto;
}

.topbar_viewLD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  background-color: #2a2a2c;
  border-bottom: 1px solid #464646;
  gap: 7px;
  position: sticky;
  top: 0;
  z-index: +5;
}
.topbar_viewLD i {
  cursor: pointer;
  color: #a3a3a3;
  transition: color 0.2s;
  margin: 0;
  padding: 0;
}

.topbar_viewLD i:nth-child(1) {
  color: #f0f0f0;
}

.taskView_division {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
}

.label {
  color: #a3a3a3;
  font-size: 14px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 25px;
}

.descriptionContainer label {
  color: #bfbfbf;
  font-size: 14px;
}

.mainSection_tv textarea {
  resize: none;
  height: 120px;
  border-radius: 5px;
  background-color: #1f1f1f;
  border: 1px solid #464646;
  color: #c8c8c8;
  padding: 8px;
  outline: none;
  transition: border 0.2s;
  width: 100%;
}

.mainSection_tv textarea:hover,
.mainSection_tv textarea:focus {
  border: 1px solid #a3a3a3;
}

.saveButton {
  align-self: flex-start;
  padding: 8px 20px;
  background-color: rgb(30, 30, 30);
  border: 1px solid rgb(50, 50, 70);
  /* border: 1px solid red; */
  color: #bfbfbf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.saveButton:hover {
  background-color: rgb(35, 35, 35);
}

.addCommentDiv {
  padding: 25px;
  display: flex;
  width: 100%;
  position: relative;
}

.addCommentDiv textarea {
  width: 100%;
  resize: none;
}

.commentButton {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: inline-block;
  padding: 5px 15px;
  outline: none;
  border: none;
  background-color: rgb(0, 119, 255);
  color: rgb(230, 230, 230);
  border-radius: 5px;
}

.styledDropdown {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(60, 60, 60);
  background-color: rgb(32, 32, 32);
  color: rgb(230, 230, 230);
  width: 25%;
}

/* Spinner Style */
.spinner {
  border: 5px solid #f3f3f3; /* Light gray background */
  border-top: 5px solid #f34100; /* Blue color for the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spins infinitely */
}

.label input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(60, 60, 60);
  background-color: rgb(32, 32, 32);
  color: rgb(230, 230, 230);
  width: 25%;
}
.label input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.task_new {
  background-color: rgb(32, 32, 32);
  width: 100%;
  color: rgb(200, 200, 200);
  font-size: 14px;
  padding: 7px 3px;
  border-radius: 5px;
  border: 1px solid rgb(60, 60, 60);
}

.Create-sub-task button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 9px;
  border-radius: 5px;
  border: 1px solid rgb(70, 70, 70);
  background-color: rgb(40, 40, 40);
  color: rgb(220, 220, 220);
  font-size: 14px;
}
.Create-sub-task button:hover {
  background-color: rgb(50, 50, 50);
}

/* Animation for spinning effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
