.mainSectionDeal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(72, 74, 77, 0.7);
  z-index: +3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.mainSectionDeal .mainDivDeal {
  height: 90%;
  max-width: 70%;
  min-width: 70%;
  background-color: rgb(30, 30, 30);
  border: 1px solid rgb(72, 74, 77);
  border-radius: 10px;
  color: white;
  overflow: auto;
}

.topbar-add-dl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid rgb(72, 74, 77);
  position: sticky;
  top: 0;
  background-color: #1e1f21;
}

.topbar-add-dl h3 {
  font-size: 24px;
  font-weight: 400;
  color: rgb(220, 220, 220);
}

.form-container {
  display: flex;
}

.ad-dl-dv {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 25px;
}
.ad-dl-dv label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #ddd;
}
.ad-dl-dv input {
  padding: 7px;
  font-size: 13px;
  color: #ddd;
  background-color: #1e1f21;
  border: 1px solid #333333;
  border-radius: 3px;
  outline: none;
  width: 100%;
}

/* Placeholder color */
.ad-dl-dv input::placeholder,
.ad-dl-dv textarea::placeholder {
  color: rgb(120, 120, 120);
}

/* Focus effect (simplified, no border) */
.ad-dl-dv input:focus,
.ad-dl-dv select:focus,
.ad-dl-dv textarea:focus,
.ad-dl-dv input:hover,
.ad-dl-dv select:hover,
.ad-dl-dv textarea:hover {
  background-color: #27282b;
}

/* Textarea resizing */
.input-div textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 120px;
  background-color: #1e1f21;
  width: 100%;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgb(60, 60, 60);
  padding: 3px;
  font-size: 14px;
  color: rgb(220, 220, 220);
}
.input-div textarea:focus,
.input-div textarea:hover {
  background-color: rgb(40, 40, 40);
}

.input-div {
  padding: 10px 0px;
}

.input-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.btn-save-deal {
  padding: 8px 5px;
  background-color: rgb(35, 35, 35);
  outline: none;
  border: 1px solid rgb(70, 70, 70);
  color: white;
  border-radius: 4px;
}
.btn-save-deal:hover {
  background-color: rgb(45, 45, 45);
}
.input-div input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.input-field {
  width: 100%;
  background-color: #1e1f21;
  border-radius: 4px;
  padding: 5px 10px;
  color: rgb(220, 220, 220);
  border: 1px solid rgb(60, 60, 60);
  font-size: 14px;
}
.dealProgressionTracker {
  padding: 25px 25px;
  padding-bottom: 0;
  gap: 15px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 50px;
  background-color: #1e1f21;
}
.dealProgressionTracker h3 {
  font-size: 26px;
  font-weight: 400;
}

.dealProgressionTracker .statusItem:first-child {
  clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0% 50%, 0% 0%);
}

.dealProgressionTracker .statusItem:last-child {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 4% 50%, 0% 0%);
}

.dealProgressionTracker .statusItem:not(:first-child):not(:last-child) {
  clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 4% 50%, 0% 0%);
}

