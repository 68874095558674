.successContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 450px;
    height: fit-content;
    padding: 100px 0px;
    background-color: white;
    box-shadow: 0px 0px 25px rgba(137, 137, 137, 0.1);
    border-radius: 25px;
    text-align: center;
}

.successContent h2 {
    font-size: 20px;
    color: rgb(50, 50, 50);
    margin-top: 15px;
    font-weight: 500;
}

.successContent p {
    font-size: 13px;
    color: rgb(30, 30, 30);
    margin-top: 10px;
    font-weight: 300;
}

.successContent .fa-check-circle {
    color: #28a745;
    font-size: 5em;
}

.successContent .fa-spinner {
    color: #f34100;
    font-size: 2em;
    margin-top: 10px;
}
