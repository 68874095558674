h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}
.dlsPG_MNSC {
  height: 100vh; /* Full viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  overflow-y: auto; /* Enables scrolling if content exceeds viewport height */
  color: white;
  padding-top: 50px; /* Space from top */
  background-color: rgb(26, 26, 26);
}

.dlsPG_MNSC__container__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid rgb(60, 60, 60);
}
.dlsPG_MNSC__container__header h3 {
  color: rgb(200, 200, 200);
  font-weight: 400;
  font-size: 18px;
}

.dlsPG_MNSC__container {
  width: 100%;
  height: 100%;
}

.dlsPG_MNSC__container__topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
  border-bottom: 1px solid rgb(60, 60, 60);
}

.dlsPG_MNSC__container__topbar .search-deals-inpt {
  width: 50%;
  margin: 0;
  padding: 0;
}
.dlsPG_MNSC__container__topbar .search-deals-inpt input {
  width: 50%;
  padding: 7px 15px 7px 40px; /* Space for icon */
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: rgb(40, 40, 40);
  color: rgb(200, 200, 200);
  border: 1px solid rgb(60, 60, 60);
  font-size: 14px;
}
.dlsPG_MNSC__container__topbar .search-deals-inpt input:hover {
  color: rgb(150, 150, 150);
}

.dlsPG_MNSC__container__topbar .search-deals-inpt input:focus {
  border: 1px solid rgb(100, 100, 100);
}

.dlsPG_MNSC__container__topbar .search-deals-inpt i {
  position: relative;
  left: 30px;
  z-index: +1;
}

.filterIcns_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
}

.filterIcns_div button {
  background-color: rgb(35, 35, 35);
  border: none;
  outline: none;
  color: rgb(200, 200, 200);
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid rgb(70, 70, 70);
  border-radius: 6px;
}

.filterIcns_div button:hover {
  background-color: rgb(45, 45, 45);
}
.filterIcns_div button i {
  color: #f34100;
}

/* End of Topbar */

.dlsinfr_dv {
  width: 100%;
  /* background-color: red; */
  padding: 0px 10px;
}

.dlsinfr_dv table {
  width: 100%;
}

.dlsinfr_dv table tr:nth-child(1) {
  /* background-color: rgb(40, 40, 40); */
  border-bottom: 1px solid rgb(60, 60, 60);
  font-size: 13px;
}

.dlsinfr_dv table th {
  padding: 6px;
  color: rgb(150, 150, 150);
  font-weight: 400;
}

.dlsinfr_dv table th:nth-child(1),
.dlsinfr_dv table th:nth-child(2),
.dlsinfr_dv table th:nth-child(3) {
  border-right: 1px solid rgb(50, 50, 50);
}

.dlsinfr_dv table td {
  padding: 9px 6px;
  font-weight: 400;
  border-bottom: 1px solid rgb(60, 60, 60);
}

.dlsinfr_dv table td:nth-child(1),
.dlsinfr_dv table td:nth-child(2),
.dlsinfr_dv table td:nth-child(3),
.dlsinfr_dv table td:nth-child(4),
.dlsinfr_dv table td:nth-child(5) {
  border-right: 1px solid rgb(50, 50, 50);
}

.dlsinfr_dv table tr:hover {
  background-color: rgb(35, 35, 35);
  cursor: pointer;
}
.dlsinfr_dv table tr td {
  font-size: 13px;
  gap: 10px;
}
